import { Controller } from "stimulus";
import LazyLoad from "vanilla-lazyload";
export default class extends Controller {
  static targets = [
    "icon",
    "empty",
    "form",
    "userLat",
    "userLng",
    "loading",
    "counter",
    "resultList",
    "result",
    "map",
  ];

  initialize() {
    this.initMap();
    var form = $(this.formTarget);

    if (this.hasFormTarget && form.data("auto-submit")) {
      form.find('input[type="submit"]').trigger("click");
    }
  }

  connect() {
    var $this = this;
    var form = $(this.formTarget);

    // only do this once the map has been loaded on the page
    google.maps.event.addListenerOnce(this.map, "tilesloaded", function() {
      // prevent state changes whenever the window is resized
      $(window).resize(function() {
        $this.windowResized = true;
        clearTimeout($this.resizeID);
        $this.resizeID = setTimeout(function() {
          $this.windowResized = false;
        }, 500);
      });

      google.maps.event.addListener(this, "bounds_changed", function() {
        if ($this.windowResized) {
          return;
        }
        if ($this.animatingAferFormLoad) {
          return;
        }

        $this.interacted_with_map = true;
        $(".form-wrapper")
          .find(".input-location")
          .addClass("disabled");
        $(".form-wrapper")
          .find(".input-supplier-distance")
          .addClass("disabled");
      });

      google.maps.event.addListener(this, "click", function(e) {
        if ($(".gm-style-iw button.gm-ui-hover-effect").length) {
          $(".gm-style-iw button.gm-ui-hover-effect").click();
        }
      });

      google.maps.event.addListener(this, "dragend", function(e) {
        if ($(".gm-style-iw button.gm-ui-hover-effect").length) {
          $(".gm-style-iw button.gm-ui-hover-effect").click();
        }
      });

      google.maps.event.addListener(this, "idle", function() {
        if (
          $this.interacted_with_map &&
          !$(".gm-style-iw button.gm-ui-hover-effect").length
        ) {
          // find bounds once you move the map and send an ajax request
          var bounded_north_east = [
            this.getBounds()
              .getNorthEast()
              .lat(),
            this.getBounds()
              .getNorthEast()
              .lng(),
          ];
          var bounded_south_west = [
            this.getBounds()
              .getSouthWest()
              .lat(),
            this.getBounds()
              .getSouthWest()
              .lng(),
          ];

          $.ajax({
            url: form.data("source"),
            type: "POST",
            dataType: "html",
            data: {
              ne: bounded_north_east,
              sw: bounded_south_west,
            },
            error: function(event) {
              $this.onPostError(event);
            },
            success: function(event) {
              $this.resultListTarget.innerHTML = event;
              $this.updateView();
            },
          });
        }
      });
    });
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: {
        lat: -34.397,
        lng: 150.644,
      },
      zoom: 8,
      mapTypeControl: false,
      fullscreenControl: false,
    });
  }

  onPostBeforeSend(event) {
    this.counterTarget.innerHTML = "&nbsp;";
    $(this.emptyTarget).addClass("d-none");
    $(this.resultListTarget).addClass("d-none");
    $(this.loadingTarget).removeClass("d-none");

    $(".form-wrapper")
      .find(".input-location")
      .removeClass("disabled");
    $(".form-wrapper")
      .find(".input-supplier-distance")
      .removeClass("disabled");
    this.interacted_with_map = false;
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.resultListTarget.innerHTML = xhr.response;
    this.updateView();
  }

  onPostError(event) {
    let [data, status, xhr] = event.detail;
    this.resultListTarget.innerHTML = "";
    this.updateView();
  }

  // view rendering
  updateView() {
    this.updateCounter();
    this.renderMarkers();
    const lazyLoadOptions = { elements_selector: ".lazy" };
    const pageLazyLoad = new LazyLoad(lazyLoadOptions);
    pageLazyLoad.update();
  }

  updateCounter() {
    var obj = $(this.counterTarget);
    let entity = obj.data("entity");
    var count = 0;
    let hasTargets = this.hasResultTarget;
    if (hasTargets) {
      count = hasTargets ? this.resultTargets.length : 0;
      let str =
        count == 1
          ? `1 ${entity}`
          : count == 0
          ? `No ${entity}es`
          : `${count} ${entity}es`;
      obj.text(`${str} matched your search`);
    } else {
      obj.text(`No ${entity}es matched your search`);
    }
    $(this.loadingTarget).addClass("d-none");
    if (count > 0) {
      $(this.resultListTarget).removeClass("d-none");
      $(this.emptyTarget).addClass("d-none");
    } else {
      $(this.resultListTarget).addClass("d-none");
      $(this.emptyTarget).removeClass("d-none");
    }
  }

  renderMarkers() {
    var icon = this.iconTarget.getAttribute("data-icon-url");
    var hoverIcon = this.iconTarget.getAttribute("data-hover-icon-url");
    var markerIcon = {
      url: icon,
      scaledSize: new google.maps.Size(19, 30), // scaled size
    };
    var markerHoverIcon = {
      url: hoverIcon,
      scaledSize: new google.maps.Size(19, 30), // scaled size
    };
    // remove old markers
    for (var marker of this.markers || []) {
      marker.setMap(null);
    }
    // add new
    this.markers = [];
    var bounds = new google.maps.LatLngBounds();
    // add user
    let lat = this.userLatTarget.value;
    let lng = this.userLngTarget.value;
    if (lat.length && lng.length) {
      var marker = new google.maps.Marker({
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          strokeColor: "#FA906C",
          scale: 4,
          strokeWeight: 8,
        },
        map: this.map,
      });
      bounds.extend(marker.getPosition());
      this.markers.push(marker);
    }
    // add results
    if (this.hasResultTarget) {
      for (var result of this.resultTargets) {
        let id = result.getAttribute("data-id");
        let lat = result.getAttribute("data-location-lat");
        let lng = result.getAttribute("data-location-lng");
        if (!lat.length || !lng.length) {
          continue;
        }
        var marker = new google.maps.Marker({
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
          icon: markerIcon,
          map: this.map,
        });
        google.maps.event.addListener(marker, "mouseover", function() {
          this.setIcon(markerHoverIcon);
          $('.result-item[data-id="' + this.locationId + '"]').addClass(
            "hover"
          );
        });
        google.maps.event.addListener(marker, "mouseout", function() {
          this.setIcon(markerIcon);
          $(".result-item").removeClass("hover");
        });

        google.maps.event.addListener(marker, "click", function() {
          // https://developers.google.com/maps/documentation/javascript/infowindows

          // If there is one window open, close it before opening a new one
          if ($(".gm-style-iw button.gm-ui-hover-effect").length) {
            $(".gm-style-iw button.gm-ui-hover-effect").click();
          }

          //
          const name = $('.result-item[data-id="' + this.locationId + '"]')
            .find(".name")
            .text();
          let description = $('.result-item[data-id="' + this.locationId + '"]')
            .find(".desc")
            .text();
          description = $.trim(description);
          if (description.length > 50) {
            description = description.substring(0, 50) + "...";
          }
          const href = $('.result-item[data-id="' + this.locationId + '"]')
            .find(".result")
            .attr("href");
          const img = $('.result-item[data-id="' + this.locationId + '"]')
            .find("img")
            .attr("data-src");

          let contentString =
            `<a href="${href}" target="_blank" id="content" class="no-underline color-black">` +
            '<div id="siteNotice">' +
            "</div>" +
            `<div style="height: 130px; width: 200px;"><img data-pin-nopin="true" class="w-100 h-100 object-fit-cover" src="${img}" /></div>` +
            '<div style="width: 200px;" id="bodyContent" class="p-2">' +
            `<div id="firstHeading" class="firstHeading font-weight-600 mb-1">${name}</div>` +
            `<div id="secondHeading" class="secondHeading text-muted" style="font-size: 10px;">${description}</div>` +
            "</div>" +
            "</a>";
          let infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
          infowindow.open(google.maps, this);
        });

        marker.locationId = id;
        bounds.extend(marker.getPosition());
        this.markers.push(marker);
      }
    }

    if (!this.interacted_with_map) {
      var $this = this;
      $this.animatingAferFormLoad = true;
      google.maps.event.addListenerOnce(this.map, "idle", function() {
        $this.animatingAferFormLoad = false;
      });
      this.map.fitBounds(bounds, 50);
      if (this.markers.length == 1) {
        this.map.setZoom(12);
      }
    }
    $(this.mapTarget).removeClass("v-none");
  }

  // box hovering
  mouseDidEnter(e) {
    var item = $(e.target).closest(".result-item");
    // select on map
    let id = item.data("id");
    let marker = this.markers.find((x) => {
      return x.locationId === id;
    });
    if (marker) {
      marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
      google.maps.event.trigger(marker, "mouseover");
    }
  }

  mouseDidLeave(e) {
    for (var marker of this.markers || []) {
      google.maps.event.trigger(marker, "mouseout");
      marker.setZIndex(google.maps.Marker.MAX_ZINDEX);
    }
  }

  enableInput() {
    if (this.interacted_with_map) {
      $(".form-wrapper")
        .find(".input-location")
        .removeClass("disabled");
      $(".form-wrapper")
        .find(".input-supplier-distance")
        .removeClass("disabled");
      var form = $(this.formTarget);

      if (this.hasFormTarget && form.data("auto-submit")) {
        form.find('input[type="submit"]').trigger("click");
      }
      // open distance dropdown if clicked from disabled mode
      $(
        ".input-wrapper .dropdown-wrapper label, .input-wrapper .dropdown-wrapper .selected-value"
      )
        .closest(".input-supplier-distance")
        .toggleClass("active opened-dropdown")
        .find("ul")
        .toggleClass("show");

      this.interacted_with_map = false;
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  initialize() {
    this.currentIndex = 0;
    $(".background-hero-images")
      .children()
      .first()
      .addClass("high-z opacity");
  }

  connect() {
    let $this = this;
    window.setInterval(function() {
      $(".background-hero-images")
        .children()
        .addClass("transition");
      $this.currentIndex = $this.currentIndex + 1;
      var length = $(".background-hero-images").children().length;
      var index = $this.currentIndex % length;
      $(".background-hero-images")
        .children()
        .removeClass("high-z opacity");
      $($(".background-hero-images").children()[index]).addClass(
        "transition high-z opacity"
      );
    }, 5000);
  }

  disconnect() {
    clearInterval();
  }
}

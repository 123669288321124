import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  onPostBeforeSend() {
    $("[data-step]").addClass("tw-hidden");

    let loadingEvent = new CustomEvent("loading-modal");
    window.dispatchEvent(loadingEvent);
  }

  onPostSuccess() {
    $(".icon-close-modal").addClass("tw-hidden");
    $("[data-step]").addClass("tw-hidden");
    $("#progress-bar").css("transform", `scaleX(1)`);

    let successEvent = new CustomEvent("success-modal");
    window.dispatchEvent(successEvent);
  }
}

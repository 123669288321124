import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  initialize() {
    this.title = this.data.get("title");
    this.description = this.data.get("description");
    this.url = this.data.get("url");
    this.img = this.data.get("img");
  }

  share(event) {
    const type = $(event.currentTarget).data("type");

    // tracking click events if google analytics enabled
    const ga = window[window["GoogleAnalyticsObject"] || "ga"];
    if (typeof ga == "function") {
      ga("send", "event", "Social Share Button", "click", type);
    }

    switch (type) {
      case "facebook":
        this.openUrl(
          `http://www.facebook.com/sharer/sharer.php?u=${this.url}&display=popup&quote=${this.description}`,
          555,
          400
        );
        break;
      case "twitter":
        this.openUrl(
          `https://twitter.com/intent/tweet?url=${this.url}&text=${this.title}`,
          650,
          300
        );
        break;
      case "pinterest":
        this.openUrl(
          `http://www.pinterest.com/pin/create/button/?url=${this.url}&media=${this.img}&description=${this.title}`
        );
        break;
      case "email":
        location.href = `mailto:?to=&subject=${this.title}&body=${this.url}`;
        break;
      case "whatsapp":
        const whatsapp_app_url = `whatsapp://send?text=${this.title}%0A${this.url}`;
        window.open(whatsapp_app_url, "_top");
        break;
      case "clipboard":
        var dummy = document.createElement("input");
        dummy.value = this.url;
        document.body.appendChild(dummy);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);

        alert("Link Copied!");
        break;
      default:
      //
    }
  }

  openUrl(url, width, height) {
    var left, opt, top;
    if (width == null) {
      width = 640;
    }
    if (height == null) {
      height = 480;
    }
    left = screen.width / 2 - width / 2;
    top = screen.height * 0.3 - height / 2;
    opt =
      "width=" +
      width +
      ",height=" +
      height +
      ",left=" +
      left +
      ",top=" +
      top +
      ",menubar=no,status=no,location=no";
    window.open(url, "popup", opt);
    return false;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable", "arrow"];

  initialize() {
    this.toggleClass = this.data.get("class") || "tw-hidden";

    this.toggleableTargets.forEach((target) => {
      if ($(target).data("selected")) {
        $(target).toggleClass(this.toggleClass);
      }
    });
  }

  connect() {
    this.toggleClass = this.data.get("class") || "tw-hidden";
  }

  toggle(event) {
    event.preventDefault();

    this.toggleableTargets.forEach((target) => {
      $(target).toggleClass(this.toggleClass);
    });

    if (this.hasArrowTarget) {
      $(this.arrowTarget).toggleClass("tw--rotate-180");
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "validateButton",
    "listWrapper",
    "validatorInput",
    "validatorCheckbox",
  ];

  initialize() {
    if (this.hasValidatorInputTarget) {
      this.validateInput();
      return true;
    }
    this.changeButtonState(false);

    this.validate = false;
  }

  validateButtons() {
    if ($(this.listWrapperTarget).find("button.tw-bg-indigo-600").length) {
      this.changeButtonState(true);
    } else {
      this.changeButtonState(false);
    }
  }

  validateInput() {
    if (this.hasValidatorInputTarget) {
      this.validate =
        this.validatorInputTargets.map((el) => $(el).val()).filter((el) => el)
          .length === this.validatorInputTargets.length;

      this.validateInputTypes();

      if (this.hasValidatorCheckboxTarget) {
        this.validate =
          this.validate && $(this.validatorCheckboxTarget).hasClass("checked");
      }

      this.changeButtonState(this.validate);
      return true;
    }
  }

  validateCheckbox() {
    if (this.hasValidatorCheckboxTarget) {
      this.validate = $(this.validatorCheckboxTarget).hasClass("checked");

      if (this.hasValidatorInputTarget) {
        this.validate =
          this.validate &&
          this.validatorInputTargets.map((el) => $(el).val()).filter((el) => el)
            .length === this.validatorInputTargets.length;

        this.validateInputTypes();
      }
    }
    this.changeButtonState(this.validate);
  }

  validateInputTypes() {
    this.validatorInputTargets.forEach((el) => {
      if ($(el).data("type") == "email") {
        this.validate =
          this.validate &&
          $(el)
            .val()
            .indexOf("@") !== -1;
      }
      if ($(el).data("type") == "password") {
        this.validate = this.validate && $(el).val().length >= 6;
      }
    });
  }

  changeButtonState(active) {
    if (active) {
      $(this.validateButtonTarget)
        .attr("disabled", false)
        .removeClass("disabled");
    } else {
      $(this.validateButtonTarget)
        .attr("disabled", true)
        .addClass("disabled");
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  sidebarClick(event){
    // 
    event.preventDefault()
    const btn = $(event.currentTarget)

    // 
    if(btn.data("cta") == "cost-estimate"){
      $("#cost-estimate-form").submit()
    } else{
      $("#supplier-form").submit()
    }
    
  }
}

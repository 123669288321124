import { Controller } from "stimulus";
let throttle = require("lodash/throttle");

export default class extends Controller {
  static targets = [];

  initialize() {
    this.onScroll = throttle(this.onScroll, 1000).bind(this);
    this.percentageThreshold = Math.floor(Math.random() * 40) + 20;
    this.modalShownBefore = false;
  }

  onScroll() {
    if (this.modalShownBefore) return false;

    if (this.percentageThreshold < this.getScrollPercent()) {
      $(this.element).removeClass("tw-hidden");
      this.setCookie();
      this.modalShownBefore = true;
    }
  }

  closeModal(e) {
    e.preventDefault();

    // Hide the modal
    $(this.element).addClass("tw-hidden");
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !$(this.element).hasClass("tw-hidden")) {
      this.closeModal(e);
    }
  }

  setCookie() {
    let expiryDate = new Date(Date.now() + 30 * (1000 * 60 * 60 * 24));
    expiryDate = expiryDate.toUTCString();
    document.cookie =
      "newsletter_modal_seen" +
      "=true; max-age=" +
      expiryDate +
      "; expires=" +
      expiryDate +
      "; path=/";
  }

  getScrollPercent() {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["photo"]

  showAll(event) {
    $(event.currentTarget).removeClass("d-lg-block")
    this.photoTargets.forEach(function(item){
      $(item).removeClass('d-lg-none')
    })
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  changeStep() {
    const step = this.data.get("step");
    const numberOfSteps = parseInt($("#number_of_modal_steps").text(), 10);
    const userIsConverted = $("#user_is_converted").text() == "true";
    let multiplier;

    // open modal
    if ($("#enquiry-modal").hasClass("tw-hidden")) {
      let openEvent = new CustomEvent("open-modal");
      window.dispatchEvent(openEvent);
    }

    // hide all steps then open relevant step
    $("[data-step]").addClass("tw-hidden");

    switch (step) {
      case "loading":
        $("#progress-bar").css("transform", `scaleX(0)`);
        let loadingEvent = new CustomEvent("loading-modal");
        window.dispatchEvent(loadingEvent);
        setTimeout(function() {
          $("[data-step]").addClass("tw-hidden");

          $("#progress-bar").css(
            "transform",
            `scaleX(${(1 / numberOfSteps) * 1})`
          );
          let reasonEvent = new CustomEvent("reason-modal");
          window.dispatchEvent(reasonEvent);
        }, 600);
        break;
      case "reason":
        multiplier = 1;
        $("#progress-bar").css(
          "transform",
          `scaleX(${(1 / numberOfSteps) * multiplier})`
        );
        let reasonEvent = new CustomEvent("reason-modal");
        window.dispatchEvent(reasonEvent);
        break;
      case "style":
        multiplier = 2;
        $("#progress-bar").css(
          "transform",
          `scaleX(${(1 / numberOfSteps) * multiplier})`
        );
        let styleEvent = new CustomEvent("style-modal");
        window.dispatchEvent(styleEvent);
        break;
      case "budget":
        multiplier = numberOfSteps === 6 ? 2 : 3;
        if (userIsConverted) ++multiplier;
        $("#progress-bar").css(
          "transform",
          `scaleX(${(1 / numberOfSteps) * multiplier})`
        );
        let budgetEvent = new CustomEvent("budget-modal");
        window.dispatchEvent(budgetEvent);
        break;
      case "start-date":
        multiplier = numberOfSteps === 6 ? 3 : 4;
        if (userIsConverted) ++multiplier;
        $("#progress-bar").css(
          "transform",
          `scaleX(${(1 / numberOfSteps) * multiplier})`
        );
        let startDateEvent = new CustomEvent("start-date-modal");
        window.dispatchEvent(startDateEvent);
        break;
      case "comment":
        multiplier = numberOfSteps === 6 ? 4 : 5;
        if (userIsConverted) ++multiplier;
        $("#progress-bar").css(
          "transform",
          `scaleX(${(1 / numberOfSteps) * multiplier})`
        );
        let commentEvent = new CustomEvent("comment-modal");
        window.dispatchEvent(commentEvent);
        break;
      case "customer-info":
        multiplier = numberOfSteps === 6 ? 5 : 6;
        $("#progress-bar").css(
          "transform",
          `scaleX(${(1 / numberOfSteps) * multiplier})`
        );
        let customerInfoEvent = new CustomEvent("customer-info-modal");
        window.dispatchEvent(customerInfoEvent);
        break;

      default:
        break;
    }
  }

  submitForm() {
    if (typeof gtag === "function") {
      gtag_report_conversion();
    }

    if (typeof fbq === "function") {
      fbq("track", "Lead");
    }

    Rails.fire($(this.data.get("form"))[0], "submit");
  }
}

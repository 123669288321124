import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button']

  updatePotentialSlug(event){
    $(this.buttonTarget).data("slug", $(event.currentTarget).data("slug"))
  }

  updateUrl(event){
    var newurl = window.location.protocol + "//" + window.location.host + "/businesses/" + $(event.currentTarget).data("slug")
    window.history.pushState({path:newurl},'',newurl);
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["highlight", "input"]

  didSelect(e) {
    $(e.target).toggleClass("selected")
    this.sync()
  }

  sync() {
    let selected = $(this.highlightTargets).filter(function(index, elem) {
      return $(elem).hasClass('selected')
    })
    let ids = selected.map(function() { return $(this).attr('data-id') })
    $(this.inputTargets).val(ids)

    // emulate change event
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("change", true, true, null)
    this.inputTarget.dispatchEvent(event)
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "qualityCurrentValue" ]

  initialize(){
    const quality = localStorage.getItem('costEstimate-quality') || "low";
    $( ".select-quality" ).val(quality);
    const text = $(".quality-dropdown").find(`[data-value='${quality}']`).text();
    $(this.qualityCurrentValueTarget).text(text)
  }

  toggleCustomDropdown(event){
    let dropdown = $(event.target).siblings(".quality-dropdown")
    this.toggleDropdown(dropdown)
  }

  selectCustomDropdown(event){
    let dropdown = $(event.target).parent()
    this.toggleDropdown(dropdown)
    $(dropdown).children().removeClass("selected")
    $(event.target).addClass("selected")
    $(this.qualityCurrentValueTarget).text($(event.target).text())
    const quality = $(event.target).data("value")

    // local storage
    $( ".select-quality" ).val(quality);
    localStorage.setItem('costEstimate-quality', quality);

    let event_calculate = document.createEvent("Events")
    event_calculate.initEvent("calculateCost", true, true)
    window.dispatchEvent(event_calculate)
  }

  toggleDropdown(dropdown){
    if(dropdown.hasClass("d-none")){
      dropdown.removeClass("d-none")
      $(this.qualityCurrentValueTarget).addClass("opened")
    } else {
      dropdown.addClass("d-none")
      $(this.qualityCurrentValueTarget).removeClass("opened")
    }
  }
}

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@stimulus/polyfills";
import Stickyfill from "stickyfilljs";

import "./stylesheet";

const objectFitImages = require("object-fit-images");

// lazy load
import LazyLoad from "vanilla-lazyload";
const lazyLoadOptions = { elements_selector: ".lazy" };
window.pageLazyLoad = new LazyLoad(lazyLoadOptions);

//
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

$(document).ready(function() {
  var elements = $(".sticky-IE");
  Stickyfill.add(elements);
  objectFitImages("img");
  window.pageLazyLoad.update();

  tippy("[data-tippy-content]", { animation: "fade", arrow: true });
});

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    const button = this.element;
    $(button).hide();
    $(document).scroll(function() {
      var y = $(this).scrollTop();
      if (y > 900) {
        $(button).fadeIn();
      } else {
        $(button).fadeOut();
      }
    });
  }
}

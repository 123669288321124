import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  sendProject(e) {
    const target = e.currentTarget;
    $.ajax({
      url: this.data.get("url"),
      dataType: "json",
      method: "POST",
      data: {
        id: this.data.get("id"),
      },
      success: function(data) {
        const event = new CustomEvent("open-success-modal");
        window.dispatchEvent(event);
        $(target).addClass("tw-hidden");
        $(".js-message-btn").attr(
          "href",
          $(".js-message-btn").attr("href") + "/" + data.id
        );
      },
      error: function(data) {
        console.log("Error:", data);
      },
    });
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [""];

  initialize() {}

  toggle(e) {
    e.preventDefault();
    const target = e.currentTarget;

    var id = $(target).data("id");
    var type = $(target).data("type");

    $.ajax({
      url: "/businesses/favourites",
      dataType: "script",
      method: type,
      data: {
        id: id,
      },
      success: function(data) {
        console.log(target);
        $(target).toggleClass(
          "tw-hidden group-hover:tw-block tw-text-primary tw-text-white"
        );
        if (type == "post") {
          $(target).data("type", "delete");
        } else {
          $(target).data("type", "post");
        }
      },
      error: function(data) {
        // error
        console.log(data);
      },
    });
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["comment", "more"]

  connect() {
    var obj = $(this.commentTarget).find('p:first-child')
    this.clamp = $clamp(obj[0], { clamp: 2, useNativeClamp: false })
    this.clamped = this.clamp.clamped;
    if (!this.clamped) {
      $(this.moreTarget).addClass('d-none')
    }
  }

  click() {
    $(this.commentTarget).html(decodeURIComponent($(this.commentTarget).data('orig')))
    $(this.moreTarget).addClass('d-none')
  }

}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "range", "value" ]

  initialize(){
    const list = JSON.parse(localStorage.getItem('costEstimate-slider'))
    if(list){
      list.forEach(function(element){
        var slider = $("#" + element.id).find(".slider").val(element.value)
      })
    }

    this.valueTarget.innerHTML = this.rangeTarget.value + " Sq M"
  }

  updateValue(event){
    var existingEntries = JSON.parse(localStorage.getItem("costEstimate-slider"))
    if(existingEntries == null) {
      existingEntries = [];
    }
    var newEntry = {
      id: $(this.element).attr("id"),
      value: event.target.value
    }
    if(existingEntries.find(function(el){ return el.id === newEntry.id }) == undefined){
      existingEntries.push(newEntry);
    } else{
      existingEntries[existingEntries.findIndex(x => x.id== newEntry.id)] = newEntry
    }
    localStorage.setItem("costEstimate-slider", JSON.stringify(existingEntries));

    this.valueTarget.innerHTML = event.target.value + " Sq M";

    let event_calculate = document.createEvent("Events")
    event_calculate.initEvent("calculateCost", true, true)
    window.dispatchEvent(event_calculate)
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["textarea", "submit"]

  connect() {
    this.replyDidChange()
  }

  replyDidChange() {
    var valid = $(this.textareaTarget).val().length > 0
    
    if($(".contact-email-input").length){
      var valid = valid && this.validateEmail($(".contact-email-input").val())
    }

    $(this.submitTarget).attr('disabled', !valid)
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

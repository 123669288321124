import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["star1", "star2", "star3", "star4", "star5", "input"]

  connect() {
    this.sync()
  }

  didSelect(e) {
    if (e.target.getAttribute('data-target') === 'rating.star1') {
      $(this.inputTargets).val(1)
    } else if (e.target.getAttribute('data-target') === 'rating.star2') {
      $(this.inputTargets).val(2)
    } else if (e.target.getAttribute('data-target') === 'rating.star3') {
      $(this.inputTargets).val(3)
    } else if (e.target.getAttribute('data-target') === 'rating.star4') {
      $(this.inputTargets).val(4)
    } else if (e.target.getAttribute('data-target') === 'rating.star5') {
      $(this.inputTargets).val(5)
    }

    // emulate change event
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("change", true, true, null)
    this.inputTarget.dispatchEvent(event)

    this.sync()
  }

  sync() {
    if (this.inputTarget.value == 1) {
      $(this.star1Target).addClass('selected')
      $(this.star2Target).removeClass('selected')
      $(this.star3Target).removeClass('selected')
      $(this.star4Target).removeClass('selected')
      $(this.star5Target).removeClass('selected')
    } else if (this.inputTarget.value == 2) {
      $(this.star1Target).addClass('selected')
      $(this.star2Target).addClass('selected')
      $(this.star3Target).removeClass('selected')
      $(this.star4Target).removeClass('selected')
      $(this.star5Target).removeClass('selected')
    } else if (this.inputTarget.value == 3) {
      $(this.star1Target).addClass('selected')
      $(this.star2Target).addClass('selected')
      $(this.star3Target).addClass('selected')
      $(this.star4Target).removeClass('selected')
      $(this.star5Target).removeClass('selected')
    } else if (this.inputTarget.value == 4) {
      $(this.star1Target).addClass('selected')
      $(this.star2Target).addClass('selected')
      $(this.star3Target).addClass('selected')
      $(this.star4Target).addClass('selected')
      $(this.star5Target).removeClass('selected')
    } else if (this.inputTarget.value == 5) {
      $(this.star1Target).addClass('selected')
      $(this.star2Target).addClass('selected')
      $(this.star3Target).addClass('selected')
      $(this.star4Target).addClass('selected')
      $(this.star5Target).addClass('selected')
    } else {
      $(this.star1Target).removeClass('selected')
      $(this.star2Target).removeClass('selected')
      $(this.star3Target).removeClass('selected')
      $(this.star4Target).removeClass('selected')
      $(this.star5Target).removeClass('selected')
    }
  }
}

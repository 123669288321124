import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "progressBar" ]

  initialize(){
    // this will any progress bars for rooms that don't have prices
    $(this.progressBarTargets).each(function(index,value){
      const original_id = $(value).attr("data-item-id").split(/\s*\-\s*/g);
      original_id.pop()
      const id = original_id.join('-')

      if($(`*[data-item-id="${id}"]`).length){
        let price = parseInt($(`*[data-item-id="${id}"]`).find(".room-price-low").text(), 10);
        if(!price){
          $(value).parent().removeClass("d-flex").addClass("d-none")
        }
      }
    })

    this.calculate()
    this.progressBar()
  }

  calculate(){
    let sqm = 0
    let cost_estimate = 0
    let labour_build_cost = 0

    let quality = $( ".select-quality" ).val();

    $(".type-item").each(function(index,item){
      let itemSqm = parseInt($(item).find(".slider")[0].value,10);
      let item_price = itemSqm * parseInt($(item).find(`.type-price-${quality}`).text(),10) || 0
      sqm += itemSqm
      cost_estimate += item_price
      labour_build_cost += item_price
    })
    $('.stage-item[data-item-id="labour-and-build-cost"] .calculated-price').text(labour_build_cost)

    $(".room-item").each(function(index,item){
      let price = parseInt($(item).find(`.room-price-${quality}`).text(),10) || 0
      let item_price = price * parseInt($(item).find(".room-number").text(),10)
      cost_estimate += item_price
      $(`.stage-item[data-item-id="${item.getAttribute('data-item-id')}-products"] .calculated-price`).text(item_price)
    })

    $(".stage-item").each(function(index,item){
      let item_price = sqm * parseInt($(item).find(`.stage-price-${quality}`).text(),10) || 0
      if (item_price > 0) {
        cost_estimate += item_price
        $(item).find('.calculated-price').text(item_price)
      }
    })

    // put initial cost estimate in the dom (for reference)
    $(".initial-cost-estimate").text(cost_estimate)

    // calculate final cost estimate
    $(".stage-item").each(function(index,item){
      if ($(item).find('.stage-percentage').length) {
        var sum_percentage = (parseInt($(item).find('.stage-percentage').text(),10) / 100)
        var stage_cost = labour_build_cost * sum_percentage
        $(item).find('.calculated-price').text(stage_cost)
        cost_estimate += stage_cost
      }
    })

    // calculate percentages
    this.progressBar(cost_estimate)

    // round final cost estimate and output it
    cost_estimate = Math.round(cost_estimate)

    // local storage
    localStorage.setItem("cost-calculator-total", JSON.stringify(cost_estimate));

    $(".final-cost-estimate").text(cost_estimate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
  }

  progressBar(cost_estimate){
    let already_filled = 0
    let stagePriceArr = []

    $(this.progressBarTargets).filter(":visible").each(function(index,value){
      var val = parseFloat($(value).find('.calculated-price').text(),10) || 0

      // 
      var newEntry = {
        id: $(value).data("real-id"),
        value: Math.round(val)
      }
      stagePriceArr.push(newEntry)

      var percentage = (val / cost_estimate) * 100;
      $(value).find(".already-filled").css("width", already_filled + "%")
      $(value).find(".this-section-fill").css("left", already_filled + "%")
      $(value).find(".this-section-fill").css("width", percentage + "%")
      already_filled += percentage
    })

    localStorage.setItem("cost-calculator-stages", JSON.stringify(stagePriceArr));
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "numberOfRooms" ]

  initialize(){
    // hides rooms without any prices
    const has_price = $(this.element).find(".room-price-low").text() || $(this.element).find(".room-price-mid").text() || $(this.element).find(".room-price-high").text()
    if(!has_price){
      $(this.element).removeClass("d-flex").addClass("d-none")
    }

    const list = JSON.parse(localStorage.getItem('costEstimate-rooms'))
    if(list){
      list.forEach(function(element){
        var slider = $("#" + element.id).find(".room-number").text(element.value)
      })
    }
  }

  changeRooms(event){
    let current_number = parseInt($(this.numberOfRoomsTarget).text(),10)

    if ($(event.target).data("operation") == "plus"){
      current_number += 1
    } else {
      current_number -= 1
    }

    if (current_number < 1){
      return
    }

    // local storage
    var existingEntries = JSON.parse(localStorage.getItem("costEstimate-rooms"))
    if(existingEntries == null) {
      existingEntries = [];
    }
    var newEntry = {
      id: $(this.element).attr("id"),
      value: current_number
    }
    if(existingEntries.find(function(el){ return el.id === newEntry.id }) == undefined){
      existingEntries.push(newEntry);
    } else{
      existingEntries[existingEntries.findIndex(x => x.id== newEntry.id)] = newEntry
    }
    localStorage.setItem("costEstimate-rooms", JSON.stringify(existingEntries));


    $(this.numberOfRoomsTarget).text(current_number)

    let event_calculate = document.createEvent("Events")
    event_calculate.initEvent("calculateCost", true, true)
    window.dispatchEvent(event_calculate)
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["inputField"];

  initialize() {
    if (this.data.has("money") && this.hasInputFieldTarget) {
      var x = window.scrollX,
        y = window.scrollY;
      $(this.inputFieldTarget).maskMoney({ prefix: "" });
      $(this.inputFieldTarget)
        .focus()
        .blur();
      window.scrollTo(x, y);
    }
  }

  customInput(event) {
    if (this.data.has("date")) {
      this.dateInput(event);
    } else if (this.data.has("button")) {
      this.buttonInput(event);
    } else {
      this.normalInput(event);
    }
  }

  buttonInput(event) {
    // can only select singular value (since it is a string input that you are writing it to)
    $(this.context.element)
      .find("button")
      .addClass(
        "tw-bg-white tw-text-gray-700 tw-border-gray-300 hover:tw-text-gray-500 active:tw-text-gray-800"
      )
      .removeClass(
        "tw-bg-indigo-600 hover:tw-bg-indigo-500 active:tw-bg-indigo-700 focus:tw-border-indigo-700 focus:tw-border-blue-300 tw-text-white"
      );
    $(event.target)
      .removeClass(
        "tw-bg-white tw-text-gray-700 tw-border-gray-300 hover:tw-text-gray-500 active:tw-text-gray-800"
      )
      .addClass(
        "tw-bg-indigo-600 hover:tw-bg-indigo-500 active:tw-bg-indigo-700 focus:tw-border-indigo-700 focus:tw-border-blue-300 tw-text-white"
      );

    let formInput = $(`#${this.data.get("id")}`);
    $(formInput).val(
      $(event.currentTarget)
        .text()
        .trim()
    );

    let validateEvent = new CustomEvent("validate-button");
    window.dispatchEvent(validateEvent);
  }

  normalInput(event) {
    let formInput = $(`#${this.data.get("id")}`);
    $(formInput).val($(event.currentTarget).val());
    if (this.data.has("money")) {
      $(event.currentTarget).maskMoney({ prefix: "" });
    }
  }

  dateInput(event) {
    const dateData = $(event.currentTarget)
      .val()
      .split("/");
    $(`#${this.data.get("id")}_1i`).val(dateData[2]);
    $(`#${this.data.get("id")}_2i`).val(parseInt(dateData[1]));
    $(`#${this.data.get("id")}_3i`).val(parseInt(dateData[0]));
  }
}

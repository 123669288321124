import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  submitForm(event){
    if(!$(event.target).hasClass('disabled')){
      if($(this.element).data("submit-form")){
        $('form.' + $(this.element).data("submit-form")).find('input[type="submit"]').click()
      } else{
        var url_hash = (window.location.hash) ? (window.location.hash.split('#')[1]) : "extension-cost-calculator"
        $('form.' + url_hash).find('input[type="submit"]').click()
      }
    }
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectedValue", "listItem"]

  initialize(){
    var is_single_value = $(this.element).data("single-value")

    ButtonValidation()
    if(!$(this.element).hasClass("input-location")){
      this.selectedValue(is_single_value, this.listItemTargets)
    }
  }

  customClick(event) {
    var element = $(event.target).find(".checkbox")
    var url_hash = (window.location.hash) ? (window.location.hash.split('#')[1]) : "cost-estimate"
    var id = $(event.target).attr("data-id")
    var is_single_value = $(this.element).data("single-value")
    var formElement = $('.' + $(this.element).attr("data-realid") + ' option[value=' + id + ']')

    if (element.hasClass("d-none")){
      if (is_single_value){
        $(this.element).find(".checkbox").addClass("d-none")
        $(this.listItemTargets).removeClass("selected")
      }
      element.removeClass("d-none")
      $(event.target).addClass("selected")
      formElement.prop('selected', true);
    } else{
      element.addClass("d-none")
      $(event.target).removeClass("selected")
      formElement.prop('selected', false);
    }

    // emulate change event
    if (formElement.length) {
      const event = document.createEvent("CustomEvent")
      event.initCustomEvent("change", true, true, null)
      formElement[0].dispatchEvent(event)
    }

    this.selectedValue(is_single_value, this.listItemTargets)
    ButtonValidation()

    if (this.element.getAttribute('data-realid') === 'user_renovation_room_ids') {
      localStorage.removeItem("costEstimate-rooms");
    }
  }

  selectedValue(is_single_value, items){
    var checked_items = $(items).has(".checkbox:not(.d-none)")
    if(is_single_value){
      if (checked_items.length) {
        $(this.selectedValueTarget).text(checked_items.attr("data-value")).removeClass('placeholder')

        var list = $(this.element).find('.dropdown-list')
        list.removeClass("show")
        $(this.element).removeClass("opened-dropdown")

      } else {
        $(this.selectedValueTarget).text($(this.selectedValueTarget).data("default")).addClass('placeholder')
      }
    } else{
      if(checked_items.length){
        var additional_items = (checked_items.length > 1) ? (" +" + ($(checked_items).has(".checkbox:not(.d-none)").length -1)) : ""
        $(this.selectedValueTarget).text($(checked_items[0]).attr("data-value") + additional_items).removeClass('placeholder')
      } else{
        $(this.selectedValueTarget).text($(this.selectedValueTarget).data("default")).addClass('placeholder')
      }
    }
  }

  reset() {
    var is_single_value = $(this.element).data("single-value")
    this.selectedValue(is_single_value, [])
    $(this.listItemTargets).removeClass("selected")
    $(this.element).find(".checkbox").addClass("d-none")
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initialize(){
    var tab_str = "tab-"
    var url_str = window.location.hash.split('#')[1]
    var id = url_str ? tab_str.concat(window.location.hash.split('#')[1]) : ""

    if(url_str && url_str.length > 3){
      $("#"+id).addClass("selected")
    } else{
      $(".hero-tab-wrapper").addClass('is-default')
      $("#tab-extension-cost-calculator").addClass("selected")
    }
    this.showCorrectInput(id, false)
  }

  selectTab(event){
    var header = $('main header')
    if (header.hasClass('ignore-events')) { return; }

    $('.tab-item').removeClass("selected")
    $(event.target).addClass("selected")
    $(".hero-tab-wrapper").removeClass('is-default')

    var id = $(event.target).attr('id')
    this.showCorrectInput(id)
  }

  showCorrectInput(id, after_initial_load = true){
    $(".input-wrapper").addClass("d-none").find(".dropdown-wrapper").removeClass("first-input-rounded last-input-rounded input-border-right")

    // prepare background image for fade transition
    $(".background-hero-images").find("div").not(".selected").css("opacity", "0")
    $(".background-hero-images").find("div").removeClass("selected").css("z-index", "-2")

    switch (id) {
      case "tab-extension-cost-calculator":
        $(".input-renovation-type").removeClass("d-none").find(".dropdown-wrapper").addClass("first-input-rounded input-border-right")
        $(".input-renovation-room").removeClass("d-none").find(".dropdown-wrapper").addClass("input-border-right")
        $(".input-location").removeClass("d-none").find(".dropdown-wrapper").addClass("last-input-rounded")
        break;
      case "tab-checklist":
        $(".input-renovation-type").removeClass("d-none").find(".dropdown-wrapper").addClass("first-input-rounded input-border-right")
        $(".input-renovation-room").removeClass("d-none").find(".dropdown-wrapper").addClass("last-input-rounded")
        break;
      case "tab-advice":
        $(".input-advice").removeClass("d-none").find(".dropdown-wrapper").addClass("first-input-rounded last-input-rounded")
        break;
      case "tab-budget-tool":
        $(".input-renovation-type").removeClass("d-none").find(".dropdown-wrapper").addClass("first-input-rounded input-border-right")
        $(".input-renovation-room").removeClass("d-none").find(".dropdown-wrapper").addClass("last-input-rounded")
        break;
       case "tab-businesses":
        $(".input-type-supplier").removeClass("d-none").find(".dropdown-wrapper").addClass("first-input-rounded input-border-right")
        $(".input-location").removeClass("d-none")
        $(".input-supplier-distance").removeClass("d-none").find(".dropdown-wrapper").addClass("last-input-rounded")
        break;
      default:
        // default will be extension-cost-calculator tab
        $(".input-renovation-type").removeClass("d-none").find(".dropdown-wrapper").addClass("first-input-rounded input-border-right")
        $(".input-renovation-room").removeClass("d-none").find(".dropdown-wrapper").addClass("input-border-right")
        $(".input-location").removeClass("d-none").find(".dropdown-wrapper").addClass("last-input-rounded")
        break;
    }

    // set background images
    if(id){
      var header = $('main header')
      var background_div = $(".background-hero-images").find("." + id)
      background_div.css("z-index", "-1").addClass("selected")
      if(after_initial_load){
        header.addClass('ignore-events')
        background_div.fadeTo(450,1)
        setTimeout(function() {
          header.removeClass('ignore-events')
        }, 450)
      } else{
        background_div.css("opacity", "1")
      }
    } else {
      $(".background-hero-images").find(".tab-extension-cost-calculator").addClass("selected").css({"z-index": "-1", "opacity": "1"})
    }

    ButtonValidation()
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  customClick(event) {
    const id = $(event.target).attr("data-id");
    const type = this.data.get("type");

    const singularSelect =
      $(event.target).attr("data-singular-select") == "true";
    let formInput = $(`#${this.data.get("id")} option[value=${id}]`);

    if (singularSelect) {
      $(this.context.element)
        .find("button")
        .addClass(
          "tw-bg-white tw-text-gray-700 tw-border-gray-300 hover:tw-text-gray-500 active:tw-text-gray-800"
        )
        .removeClass(
          "tw-bg-indigo-600 hover:tw-bg-indigo-500 active:vbg-indigo-700 focus:vborder-indigo-700 focus:vborder-blue-300 tw-text-white"
        );
      $(event.target)
        .removeClass(
          "tw-bg-white tw-text-gray-700 tw-border-gray-300 hover:tw-text-gray-500 active:tw-text-gray-800"
        )
        .addClass(
          "tw-bg-indigo-600 hover:tw-bg-indigo-500 active:tw-bg-indigo-700 focus:tw-border-indigo-700 focus:tw-border-blue-300 tw-text-white"
        );
      $(`#${this.data.get("id")} option[data-type='${type}']`).removeProp(
        "selected"
      );
    } else {
      $(
        `#${this.data.get("id")} option[value=${$(this.context.element)
          .find("[data-singular-select='true']")
          .attr("data-id")}]`
      ).removeProp("selected");
      $(this.context.element)
        .find("[data-singular-select='true']")
        .addClass(
          "tw-bg-white tw-text-gray-700 tw-border-gray-300 hover:tw-text-gray-500 active:tw-text-gray-800"
        )
        .removeClass(
          "tw-bg-indigo-600 hover:tw-bg-indigo-500 active:tw-bg-indigo-700 focus:tw-border-indigo-700 focus:tw-border-blue-300 tw-text-white"
        );
    }
    if (formInput.prop("selected")) {
      formInput.removeProp("selected");
    } else {
      formInput.prop("selected", true);
    }

    let validateEvent = new CustomEvent("validate-button");
    window.dispatchEvent(validateEvent);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["rating", "submit", "secretForm"]

  connect() {
    this.check()
  }

  ratingDidChange() {
    this.check()

    let valid = (this.ratingTarget.value && this.ratingTarget.value.length > 0)
    if (valid) {
      Rails.fire(this.secretFormTarget, 'submit')
    }
  }

  check() {
    let valid = (this.ratingTarget.value && this.ratingTarget.value.length > 0)
    $(this.submitTarget).attr('disabled', !valid)
  }

}
